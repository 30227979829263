.gradient-background-container {
  background: linear-gradient(180deg, rgba(222,236,247,1) 0%, rgba(255,255,255,1) 100%);
}

.background-image-container {
  position: relative;
  
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1
  }

  &__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: @qd-blue;
    opacity: 0.88;
    z-index: 2;
  }

  &__body {
    position: relative;
    z-index: 3;
  }

  &__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, rgba(0, 0, 0, 1), transparent);
    opacity: 0.88;
    z-index: 2;
  }
}