.App__is-mobile .qd-carousel-wrapper {
  height: 100vh !important;
}

.qd-carousel-wrapper {
  position: relative;
  height: 100vh;

  .ant-carousel .slick-dots {
    justify-content: flex-end;
    margin-right: 64px;
    bottom: 24px;

    & > li > button {
      opacity: 1;
    }

    & > li.slick-active button {
      background: @qd-red-2;
    }

  }
}

.App__is-mobile {
  .qd-carousel-intro-wrapper {
    left: 24px;
    padding: 16px;
    bottom: 24px;
  }

}

.qd-carousel-intro-wrapper {
  position: absolute;
  z-index: 4;
  bottom: 60px;
  left: 56px;
  border-left: 8px solid @qd-red-2;
  padding: 25px 35px 25px 27px;

  &__header {
    font-size: 48px;
    color: white;
    margin: 0;
  }
}

.qd-carousel-item {
  position: relative;
}

.qd-carousel-control {
  background: white;
  position: absolute;
  right: 24px;
  bottom: 12px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 11;
  // font-weight: 900;

  &:hover {
    background: @qd-red-2;
    color: white;
    transition: 0.3s;
  }
}