@import '~antd/dist/antd.less';

body {
  position: inherit !important;
  width: auto !important;
  overflow: revert !important;
}

.App {

  animation-name: fadeIn;
  animation-duration: .5s;
  // animation-timing-function: linear;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;

  &__tip {
    margin-top: 12px;
  }

  &__completed {
    animation-name: fadeOut;
    animation-duration: .5s;

    @keyframes fadeOut {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
}

.m-0 {
  margin: 0 !important;
}