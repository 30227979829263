.span-clickable {
  cursor: pointer;
  color: white;
}

.qd-divider-wrapper__text {
  font-style: italic;
  font-size: 20px;
  // font-weight: 600;
  white-space: nowrap;
}