.event-card-wrapper {
  cursor: pointer;
  width: 388px;
  visibility: hidden;
  flex-shrink: 0;

  &__active {
    visibility: visible;
    animation-duration: 0.8s;
    animation-name: cardSlideIn;
    opacity: 1;
  }

  @keyframes cardSlideIn {
    0% {
      transform: translateY(75px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  &:hover {
    filter: drop-shadow(0 0 26px rgba(0,0,0,.09));
    transition: .5s;
  }

  .ant-card-cover {
    overflow: hidden;
  }

  // &__category-divider {
  //   font-family: adobe-caslon-pro, sans-serif;
  // }

  &__header {
    color: @qd-blue;
    // font-weight: 900;
    margin-top: 24px;
    margin-bottom: 16px;
    line-height: 1.1;
    text-transform: uppercase;
    font-size: 1.4375rem;
  }

  &__summary {
    color: @qd-blue;
    margin-bottom: 48px !important;
    // font-weight: 400;
    font-size: 1rem;
  }

  &__footer {
    height: 20px;
    width: calc(388px - 48px);
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 24px;

    &__arrow {
      color: @qd-red-2;
      animation-duration: 0.5s;

      &::before {
        content: "";
        width: 120px;
        height: 1px;
        background-color: @qd-red-2;
        position: absolute;
        left: 40px;
        top: 0px;
      }

      &::after {
        content: "\E90D";
        font-family: IcoMoon;
        position: absolute;
        left: 96px;
        top: -8px;
        font-size: .6875rem;
      }

      &__active {
        animation-name: customSlideIn;
        transform: translateX(-50px);
      }

      &__leave {
        animation-name: customSlideOut;
        transform: translateX(-100px);
      }

      @keyframes customSlideIn {
        0% {
          transform: translateX(-100px);
        }
        100% {
          transform: translateX(-50px);
        }
      }

      @keyframes customSlideOut {
        0% {
          transform: translateX(-20px)
        }
        100% {
          transform: translateX(-100px)
        }
      }
    }


    &__read-more {
      // font-weight: 600;
      color: @qd-red-2;
      animation-duration: 0.5s;

      &__active {
        animation-name: customFadeIn;
        opacity: 1;
      }

      &__leave {
        animation-name: customFadeOut;
        opacity: 0
      }

      @keyframes customFadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes customFadeOut {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }
}

.event-card-image-container {
  height: 242px;
  object-fit: cover;
  animation-duration: 0.5s;

  &__active {
    animation-name: zoomIn;
    transform: scale(1.2);
  }

  &__leave {
    animation-name: zoomOut;
    transform: scale(1);
  }

  @keyframes zoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes zoomOut {
    0% {
      transform: scale(1.2)
    }
    100% {
      transform: scale(1)
    }
  }
}