.wechat-qrcode-modal {
  .ant-modal-confirm-btns {
    display: none;
  }
}

.welcome-block-wrapper {
  padding: 158px 45px 75px;

  &__header {
    text-align: center;
    letter-spacing: -1.5px;
  }

  &__description {
    display: flex;
    justify-content: center;
    margin: 16px 0;

    &>p {
      width: 80%;
      line-height: 1.9;
      max-width: 960px;
    }
  }

  &__poster-card {
    display: flex;
    justify-content: center;

    &>div {
      display: flex;
      // gap: 1px;
    }
  }
}

@media (min-width: 1300px) {
  .fact-block-wrapper {
    padding: 150px calc(50vw - 620px) 100px !important;
  }
}

.fact-block-wrapper {
  padding: 150px 45px 100px;

  &__body {
    display: flex;
    // max-width: 1200px;
    gap: 120px;
    flex-wrap: wrap;

    &__btn {
      visibility: hidden;
    }

    &__description {
      flex: 30%;
      // flex-grow: 0;
      min-width: 300px;
      visibility: hidden;
    }

    &__active {
      animation-name: rightSlideIn;
      animation-duration: 1s;
      visibility: visible;
    }

    .fact-carousel-wrapper {
      // padding-top: 100px;
      flex: 50%;
      width: 50%;
      visibility: hidden;


      &__active {
        animation-name: leftSlideIn;
        animation-duration: 1s;
        visibility: visible;
      }

      &__item {
        display: flex;
        gap: 96px;
        padding: 48px 0;
        flex-direction: column;

        &>div {
          display: flex;
          gap: 48px;
          justify-content: space-between;
        }
      }

      .slick-dots {
        display: none !important;
      }
    }

    @keyframes rightSlideIn {
      0% {
        transform: translateX(-150px);
        opacity: 0;
      }

      100% {
        transform: none;
        opacity: 1;
      }
    }

    @keyframes leftSlideIn {
      0% {
        transform: translateX(150px);
        opacity: 0;
      }

      100% {
        transform: none;
        opacity: 1;
      }
    }
  }

}

.event-block-wrapper {
  padding: 158px 30px 100px;

  &__header {
    text-align: center;
    margin-bottom: 25px;
  }

  &__body {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1300px) {
  .video-block-wrapper {
    padding: 82px calc(50vw - 620px) !important;
  }
}

.video-block-wrapper {
  padding: 82px 45px;
  height: 600px;
  // position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  &__descriptor {
    position: absolute;
    // bottom: 82px;
    // left: 45px;
    // left: 10%;
    max-width: 640px;

    &>p {
      font-size: 18px;
    }
  }
}

.App__is-mobile {
  .footer-block-wrapper {
    padding: 48px;

    &__body {
      flex-direction: column;
      // padding: 24px;
      gap: 24px;

      // img {
      //   margin: auto;
      // }

      &>div:first-child {
        gap: 24px;
      }
    }

    &__certificate {
      flex-wrap: wrap;
      // flex-direction: column;
      align-items: center;

      &>img {
        height: 64px;
      }
    }
  }

  .fact-block-wrapper {
    flex-direction: column;
  }

  .video-block-wrapper {
    height: 600px;
    position: relative;

    &__descriptor {
      position: absolute;
      bottom: 36px;
      left: 36px;
      max-width: calc(100vw - 72px);

      &>h1 {
        font-size: 36px;
      }

      &>p {
        font-size: 16px;
      }
    }
  }

  .welcome-block-wrapper {
    padding: 158px 45px 75px;


    &__header {
      text-align: center;
      letter-spacing: -1.5px;
    }

    &__description {
      display: flex;
      justify-content: center;
      margin: 16px 0;

      &>p {
        width: 80%;
        line-height: 2;
        max-width: 960px;
        font-size: 16px !important;
      }
    }

    &__poster-card {
      display: flex;
      justify-content: center;

      &>div {
        width: 100%;
        display: flex;
        // gap: 1px;
        flex-wrap: wrap;

        &>div {
          width: calc(50vw - 0.5px);
        }
      }
    }
  }


  .fact-block-wrapper {
    display: flex;
    margin: auto;
    padding: 120px 36px 140px;
    max-width: 1200px;
    gap: 48px;

    &>div {
      flex: 50%;
    }

    .fact-carousel-wrapper {
      width: 100%;

      &__item {
        display: flex;
        gap: 96px;
        padding: 48px 0;
        flex-direction: column;

        &>div {
          display: flex;
          gap: 48px;
          justify-content: space-between;
          flex-direction: column;
        }
      }

      .slick-dots {
        display: none !important;
      }
    }
  }
}

.footer-block-wrapper {
  padding: 80px 72px 48px;

  &__logo {
    max-width: 100%;
    height: auto;

    max-height: 60px;
    margin-bottom: 36px;
    // height: fit-content;
  }

  &__body {
    display: flex;
    // gap: 72px;
    flex-direction: column;
    align-items: center;
  }

  &__certificate {
    display: flex;
    justify-content: center;
    gap: 32px;

    &>img {
      height: 80px;
      cursor: pointer;
      // width: 

      &:hover {
        transition: .5s;
        transform: translateY(-8px) scale(1.05);

      }
    }
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    align-items: center;
    font-size: 13px;
    color: grey;
    gap: 4px;
    text-align: center;
  }

  &__popular-link {
    cursor: pointer;
    font-size: 16px;
    // font-weight: 400;
  }

  &__contact-item {
    font-size: 16px;
    // font-weight: 400;
  }
}