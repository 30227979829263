.qd-fact-stats-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 300px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
  }

  p {
    flex-shrink: 1;
    line-height: 1.2;
    margin-top: 10px;
    // font-family: adobe-caslon-pro, sans-serif;
    font-size: 18px;
  }
}