.news-event-wrapper {
  padding-top: 24px;
  max-width: 780px;
  justify-content: center;
  gap: 24px;
  margin: auto;
  margin-bottom: 24px;

  &__cover-image {
    position: relative;

    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__current-menu-list {

    &-title {
      color: @qd-blue;
      // font-size: 16px;
      // font-weight: 900;
    }

    &-item {
      cursor: pointer;
      width: 240px;

      &__active {
        background: @qd-lightblue-2;
      }

      &:hover {
        background: @qd-lightblue-2;
      }
    }
  }

  &__main-container {
    width: 100%;
    flex-grow: 1;

    &__html-wrapper {
      // max-width: 760px;
      overflow: auto;
      font-size: 18px;
      color: #606060;
      // font-family: brandon-grotesque,sans-serif !important;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: @qd-blue;
        // font-weight: 600;
        // font-family: brandon-grotesque,sans-serif !important;
      }

      iframe {
        width: 100% !important;
      }

      hr {
        border-top: 1px solid #e9e9e9;
        border-bottom: none;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      p,
      li {
        font-weight: 400;
        font-size: 15px;
        // font-family: brandon-grotesque,sans-serif !important;
      }

      img {
        max-width: 100% !important;
        height: auto !important;
      }
    }

    &__header-wrapper {
      color: @qd-red-2;
      // font-weight: 900;
    }
  }

  .ant-skeleton-title {
    height: 30px;
    margin-top: 10px;
  }
}