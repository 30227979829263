body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App__zh {
  font-family: 'Noto Sans SC', sans-serif;

  pre {
    font-family: 'Noto Sans SC', sans-serif !important;
    white-space: normal !important;
    margin: auto !important;
    overflow: hidden;
  }

  .font-weight-regular-zh {
    font-weight: 400;
    
    // border: 1px solid red;
  }

  .font-weight-medium-zh {
    font-weight: 500;
    
    // border: 1px solid red;

  }

  .font-weight-bold-zh {
    font-weight: 700;

    // border: 1px solid red;

  }
  
  .font-weight-black-zh {
    font-weight: 900;

    // border: 1px solid red;
  }
}

.App__en {
  font-family: 'brandon-grotesque';

  pre {
    font-family: 'brandon-grotesque' !important;
    white-space: normal !important;
    margin: auto !important;
    overflow: hidden;
  }

  .font-weight-regular-en {
    font-weight: 400;

    // border: 1px solid blue;
  }

  .font-weight-medium-en {
    font-weight: 500;

    // border: 1px solid blue;
  }
  
  .font-weight-bold-en {
    font-weight: 700;

    // border: 1px solid blue;
  }
  
  .font-weight-black-en {
    font-weight: 900;

    // border: 1px solid blue;
  }
}

// .image-loading-placeholder {
//   background: transparent url('https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg') center no-repeat;
// }