.school-switcher-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: white;
  padding-right: 18px;
  height: 50px;
  // margin-right: 148px;
  background: #f8f8f8;
  margin-right: 200px;

  &__dropdown:hover {
    color: @qd-red-2;
  }

  .lang-switcher-wrapper__icon {
    border-radius: 4px 0 0 4px;
    background: @qd-lightblue-2;
    height: 50px;
    width: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // &::before {
    //   font-family: IcoMoon;
    //   content: "\E912";
    //   font-size: 22px;
    // }

    &:hover {
      background: #3f567f;
      color: white;
    }
  }

  .lang-switcher-wrapper__dropdown {
    font-size: 15px;
    font-weight: 900;
    // font-weight: 600;
    // width: 150px;
  }
}


.lang-label-span {
  // font-weight: 600;
  color: @qd-blue;

  &__active {
    color: @qd-red-2;
  }
}

.school-label-span {
  // font-weight: 600;
  color: @qd-blue;

  &__active {
    color: @qd-red-2;
  }
}

.dropdown-content {
  min-width: 150px;
  background: #f8f8f8;
  box-shadow: 0 0 10px rgb(0 0 0 / 25%);

  &__title {
    color: @qd-blue;
    // font-weight: 900;
    padding: 0 12px;
  }

  .ant-dropdown-menu {
    background: #f8f8f8;
  }
}

.dropdown-content .ant-dropdown-menu {
  box-shadow: none;
}