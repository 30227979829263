.qd-paragraph-wrapper {
  font-size: 16px;
  color: white;
  // font-weight: 400;
  // line-height: 2;
}

.App__is-mobile {
  .qd-paragraph-wrapper {
    line-height: 1.7 !important;
  }
}