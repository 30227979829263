.mobile-header-wrapper {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 6px 18px;
  background: white;
  border-bottom: 1px solid rgba(19, 35, 75, 0.3);

  .navbar-wrapper__sidebar-trigger {
    border-radius: 4px;
    transform: scale(0.8);
    transform-origin: right;
  }
}

.header-wrapper {
  position: fixed;
  // font-weight: 900;
  z-index: 1000;
  width: 100%;
  padding: 30px 50px;
  box-sizing: border-box;

  &__sticky-mode {
    background: white;
    transition: 0.5s;
    padding: 16px 50px;
    border-bottom: 1px solid rgba(19,35,75,.3);
    z-index: 200;
  
    &__nav-header {
      width: 100%;
      position: fixed;
      top: 85px;
      z-index: 199;
      transition: 0.3s;
      opacity: 0;
      

      &__active {
        background-color: #13234Bcc;
        backdrop-filter: saturate(180%) blur(5px);
        -webkit-backdrop-filter: saturate(180%) blur(5px);
        opacity: 1;
        border-bottom: 1px solid rgba(19,35,75,.3);
        animation-duration: 0.3s;
        animation-name: navSlideIn;
      }

      @keyframes navSlideIn {
        0% {
          transform: translateY(-20px);
        }
    
        100% {
          transform: none;
        }
      }
  

      &__span {
        color: white;
        cursor: pointer;
        font-size: 15px;

        // & > a {
        //   color: @qd-blue;
        // }
    
        margin: 0 15px 5px;
        text-transform: uppercase;
        // font-size: .82353em;
        // font-weight: 900;
    
        // &__active > a {
        //   color: @qd-red-2;
        // }
      }
    }
  }
}

.App__is-mobile {
  .header-wrapper {

    .school-switcher-wrapper {
      margin-right: 0;
    }

    &__mobile-logo {
      position: absolute;
      z-index: 1;
      width: 100%;
      justify-content: center;
      display: flex;
      
      img {
        margin-top: 80px;
        width: 200px;
      }
    }
  }
}