.gradient-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(0, rgba(0, 0, 0, 0.3), transparent);

  &__caption {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 12px;
  }
}

.App__is-mobile {
  .page-wrapper {
    flex-direction: column;

    &__current-menu-list {

      &-item {
        cursor: pointer;
        width: 100%;

      }
    }
  }
}

.page-wrapper {
  // padding-top: 24px;
  // max-width: 1200px;
  // justify-content: center;
  gap: 24px;
  // margin: auto;
  // margin-bottom: 24px;

  &__toc-container {
    background: #deecf770;
    width: 360px;
    flex-shrink: 0;
    padding: 96px 24px 96px 12px;

    &__body {
      position: sticky;
      top: 185px;

      &>ol {
        list-style-type: none;
        padding-left: 24px;

        a {
          color: @qd-blue;
          // font-weight: 600;
        }

        a.is-active-link {
          color: @qd-red-2;
        }

        li {
          font-size: 20px;
          margin-bottom: 6px;

          &>a {
            font-weight: 700;

            &::before {
              content: "+ "
            }
          }

          &>ol {
            list-style-type: none;
            padding-left: 24px;
            margin: 6px 0 12px;

            li {
              font-size: 16px;
              margin-bottom: 6px;

              &>a {
                font-weight: 500;

                &::before {
                  content: "• "
                }
              }
            }
          }
        }
      }
    }
  }

  &__cover-image {
    position: relative;

    img {
      // margin-top: 200px;
      height: calc(100vh - 63px);
      width: 100%;
      object-fit: cover;
    }
  }

  &__current-menu {
    position: sticky;
    padding: 18px 30px 16px;
    background-color: #f8f8f890;
    backdrop-filter: saturate(180%) blur(10px);
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    border-bottom: 1px solid rgba(19, 35, 75, .3);
    z-index: 199;
  }

  &__current-menu-item {
    // flex-shrink: 0;

    &>a {
      color: @qd-blue;
    }

    margin: 0 15px 5px;
    text-transform: uppercase;
    font-size: 15px;
    // font-weight: 900;

    &__active>a {
      color: @qd-red-2;
    }
  }

  &__main-container {
    margin: 48px 0;
    width: 100%;
    flex-grow: 1;
    padding: 0 48px 0 24px;

    &__html-wrapper {
      // max-width: 760px;
      overflow: auto;
      font-size: 18px;
      flex-shrink: 1;
      color: #606060;
      word-break: break-work;
      // font-family: brandon-grotesque,sans-serif !important;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: @qd-blue;
        // font-weight: 600;
        // font-family: brandon-grotesque,sans-serif !important;
      }

      iframe {
        width: 100% !important;
      }

      hr {
        border-top: 1px solid #e9e9e9;
        border-bottom: none;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      p,
      li {
        font-weight: 400;
        font-size: 15px;
        // font-family: brandon-grotesque,sans-serif !important;
      }

      img:not(.ok-img) {
        max-width: 100% !important;
        height: auto !important;
      }
    }

    &__faculty-list-wrapper {
      max-width: 100%;
      overflow: auto;
      overflow-x: hidden;
      // display: flex;
      // gap: 32px;
      // flex-wrap: wrap;


      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: @qd-blue;
        // font-weight: 600;
        // font-family: brandon-grotesque,sans-serif !important;
      }

      hr {
        border-top: 1px solid #e9e9e9;
        border-bottom: none;
      }

      h1 {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }

      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
      }

      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        font-weight: 400;
        font-size: 15px;
        // font-family: brandon-grotesque,sans-serif !important;
      }
    }

    &__header-wrapper {
      color: @qd-red-2;
      font-size: 30px;
      // font-family: 'Noto Serif SC', serif;
      // font-weight: 900;
    }
  }

  .ant-skeleton-title {
    height: 30px;
    margin-top: 10px;
  }
}