.qd-header-wrapper {
  // font-weight: bold;
  font-size: 56px;
  margin: 0;
  line-height: 1;
}

// .App__en .qd-header-wrapper {
//   font-family: brandon-grotesque,sans-serif;
//   font-weight: 900;
// }

// .App__zh .qd-header-wrapper {
//   font-family: 'Noto Sans SC', sans-serif;
// }

.App__is-mobile {
  .qd-header-wrapper {
    // font-weight: bold;
    font-size: 48px;
    margin: 0;
    line-height: 1;
  }
}