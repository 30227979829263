@cardHeight: 500px;

.poster-card-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: @cardHeight;
  overflow: hidden;
  visibility: hidden;

  &__visible {
    visibility: visible;
    animation-duration: 0.8s;
    animation-name: cardSlideIn;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.034);
    transition: .5s;
    filter: drop-shadow(0 0 43px rgba(0,0,0,.7));
  }

  & > img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .poster-card-gradient-container {
    width: 100%;
    height: 40%;
    background: linear-gradient(0, rgba(0, 0, 0, 1), transparent);
    position: absolute;
    bottom: 0;
  }

  .poster-card-background-container {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    background: rgba(36, 49, 84, 0.8);
    animation-duration: 0.5s;

    &__active {
      animation-name: colorFadeIn;
      opacity: 1;
    }
  
    &__leave {
      animation-name: colorFadeOut;
      opacity: 0;
    }
  
    @keyframes colorFadeIn {
      0% {
        opacity: 0;
      }
  
      100% {
        opacity: 1;
      }
    }
  
    @keyframes colorFadeOut {
      0% {
        opacity: 1;
      }
  
      100% {
        opacity: 0;
      }
    }
  }

  .poster-card-image-container{
    animation-duration: 0.5s;

    &__active {
      animation-name: zoomIn;
      transform: scale(1.2);
    }
  
    &__leave {
      animation-name: zoomOut;
      transform: scale(1);
    }
  
    @keyframes zoomIn {
      0% {
        transform: scale(1);
      }
  
      100% {
        transform: scale(1.2);
      }
    }
  
    @keyframes zoomOut {
      0% {
        transform: scale(1.2);
      }
  
      100% {
        transform: scale(1);
      }
    }
  }

  @keyframes cardSlideIn {
    0% {
      transform: translateY(75px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  // @keyframes cardSlideOut {
  //   0% {
  //     transform: none;
  //   }

  //   100% {
  //     transform: none;
  //   }
  // }
}



.poster-card-title {
  height: 60px;
  color: white;
  // font-weight: 600;
  font-size: 24px;
  position: relative;
  top: -560px;
  text-align: center;
  animation-duration: 0.5s;

  &__active {
    animation-name: fadeOut;
    opacity: 0;
  }

  &__leave {
    animation-name: fadeIn;
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.poster-card-body {
  text-align: center;
  position: relative;
  top: -40px;
  animation-duration: 1s;
  height: @cardHeight;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &__title {
    height: 40px;
    color: white;
    // font-weight: 600;
    font-size: 22px;
    line-height: 1.2;
  }

  &__description {
    color: white;
    overflow-y: auto;
    height: 160px;
    // font-weight: 400;
    font-size: 18px;

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__active {
    animation-name: posterCardSlideFadeIn;
    top: -@cardHeight;
  }

  &__leave {
    animation-name: posterCardSlideFadeOut;
    top: 40px;
  }

  // @keyframes posterCardSlideFadeIn {
  //   0% {
  //     top: 40px;
  //     opacity: 0;
  //   }
  //   100% {
  //     top: -@cardHeight;
  //     opacity: 1
  //   }
  // }

  // @keyframes posterCardSlideFadeOut {
  //   0% {
  //     top: -@cardHeight;
  //     opacity: 1;
  //   }
  //   100% {
  //     top: 40px;
  //     opacity: 0;
  //   }
  // }
}