

.navbar-wrapper {
  height: 52px;
  border-radius: 4px;
  display: flex;
  

  &__sidebar-trigger {
    background: @qd-red-2;
    color: white;
    border: none;
    border-radius: 0;
    height: 100%;
    display: inline-block;
    // font-size: 18px;
    border-radius: 0 4px 4px 0;

    & > span::before, & > span::after {
      font-family: IcoMoon;
      position: relative;
      top: 2px;
    }

    & > span::before {
      content: "\E91C";
    }

    & > span::after {
      content: "\E90E";
    }

    &:hover, &:active, &:focus {
      background: @qd-red;
      color: white;
      border-color: @qd-red;
    }
  }

  &__btn {
    color: @qd-blue;
    height: 100%;
    // font-weight: 900;
    padding: 0 20px;
    // box-sizing: border-box;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-size: 15px;
    

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:not(:last-child) {
      border-right: 0.5px solid #d9d9d9 !important;
    }

    &:hover {
      color: @qd-red-2;
      border-color: #d9d9d9;
      background-color: #f8f8f8;
    }
  }
}

.sidebar-drawer-wrapper {
  .school-switcher-wrapper {
    margin-right: 0 !important;
    margin-bottom: 16px !important;
  }

  .sidebar-navbar-wrapper__btn {
    // width: 50%;
    background: #e5e5e5;
    height: 52px;
    color: @qd-blue;
    font-size: 14px;
  }

  .ant-drawer-header {
    display: none !important;
  }

  &__search-input {
    height: 100%;
    background: #e5e5e5;
    font-size: 1.0624rem;

    .ant-input {
      background: #e5e5e5;
    }
  }

  .collapse-panel-page-item {
    & > a {
      color: @qd-blue;
      font-size: 1.0625rem;
      text-transform: uppercase;

      &:hover {
        color: @qd-red-2;
      }
    }
  }

  .ant-collapse-content-box {
    padding-left: 0;
  }

  .collapse-panel-header {
    font-size: 1.8rem;
    color: @qd-blue;

    &__active{
      color: @qd-red-2
    }
  }

  .sidebar-search-icon {
    &::after {
      font-family: IcoMoon;
      content: "\E91C";
      color: @qd-red-2;
      // font-weight: 400;
      margin: 0 8px;
    }
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sidebar-collapse-panel-expand-icon {
    &::after {
      font-family: IcoMoon;
      content: "\F105";
      color: @qd-red-2;
      font-size: 22px;
      position: relative;
      top: 4px;
    }
  }

  .sidebar-collapse-panel-collapse-icon {
    &::after {
      font-family: IcoMoon;
      content: "\F107";
      color: @qd-red-2;
      font-size: 22px;
      position: relative;
      top: 4px;
    }
  }
  
  .sidebar-close-icon {
    background: @qd-red-2;
    height: 100%;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 0 4px 4px 0;

    &::after {
      font-family: IcoMoon;
      content: "\E922";
      font-size: 20px;
    }
  }
}

.my-qd-label-span {
  // font-weight: 600;
  color: @qd-blue;

  &__active {
    color: @qd-red-2;
  }
}