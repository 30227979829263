.menu-container {
  position: relative;

  &__cover-image {
    position: relative;
  
    img {
      // margin-top: 200px;
      height: 100vh;
      width: 100%;
      object-fit: cover;
    }
  }

  &__current-menu-list {
    width: 240px;
    position: absolute;
    bottom: 60px;
    z-index: 10;
    right: 56px;
    padding: 16px;
    background-color: #E5F1FAcc;
    border-radius: 4px;
  }

  &__current-menu-list-item {
    padding-left: 16px;
    color: @qd-blue;
  }
}