.section-divider-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // top: -42px;
  position: absolute;
  z-index: 10;
  visibility: hidden;

  &__active {
    animation-name: dividerSlideIn;
    animation-duration: 1s;
    visibility: visible;
  }

  @keyframes dividerSlideIn {
    0% {
      transform: translateY(75px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  &__vertical {
    // height: 84px;
    border-left: 3px solid @qd-red-2;
    margin: auto;
  }

  img {
    margin-top: 24px;
  }
}