@animationDuration: 0.3s;
@slideHeight: 48px;

.qd-button-container {
  position: relative;
  overflow: hidden;

  display: inline-flex;
  height: 48px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.qd-button-body-container {
  position: relative;
  z-index: 2;
  font-size: 14px;
  // font-weight: 600;
}

.qd-button-border-container {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  animation-duration: @animationDuration;
  animation-timing-function: linear;

  &__active {
    animation-name: fadeOut;
    opacity: 0;
  }

  &__leave {
    animation-name: fadeIn;
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

.qd-button-animation-container {
  top: @slideHeight;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  animation-duration: @animationDuration;
  animation-timing-function: linear;
  z-index: 1;

  &__active {
    top: 0;
    animation-name: slideIn;
  }

  &__leave {
    animation-name: slideLeave;
  }

  @keyframes slideIn {
    0% {
      top: @slideHeight;
    }

    100% {
      top: 0px
    }
  }

  @keyframes slideLeave {
    0% {
      top: 0px;
    }

    100% {
      top: @slideHeight
    }
  }
}