.campus-visit-form-container {
  border: 1px solid #e9e9e9;
  padding: 24px 32px;

  h2 {
    font-weight: 600;
    color: @qd-blue;
  }

  &__input-number {

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0 !important;
    }

    input {
      -moz-appearance: textfield;
    }
  }
}