.news-container {
  padding: 96px;

  &__cover-image {
    position: relative;

    img {
      // margin-top: 200px;
      height: 400px;
      width: 100%;
      object-fit: cover;
    }
  }

  &__body {
    padding: 72px 0 24px;
    // margin: auto;
    display: flex;
    justify-content: center;
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 24px 0;
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid #f0f0f0;
  }
}